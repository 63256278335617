import { motion } from "framer-motion";

const MyComponent = () => {
  const sentence = "أسهل عليج تلقين صالونج المفضل 💞 ";
  const words = sentence.split(" ");

  const containerVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      y: -20,
      opacity: 1,
      transition: {
        staggerChildren: 0.38,
        delayChildren: 0.5,
      },
      type: "spring",
      stiffness: 50,
    },
  };

  const wordVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 50 },
    }, // Move the transition property here
  };

  return (
    <motion.span
      className="text-white text-sm"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {words.map((word, index) => (
        <motion.span
          style={{ color: "white" }}
          key={index}
          variants={wordVariants}
          className=" font-black drop-shadow-2xl "
        >
          {word}{" "}
        </motion.span>
      ))}
    </motion.span>
  );
};

export default MyComponent;
