import React, { lazy, Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

const Home = lazy(() => import("./Componantes/Home"));
// const HomeSoon = lazy(() => import("./Componantes/HomeSoon"));
const Contact = lazy(() => import("./Componantes/pages/Contact"));
const Services = lazy(() => import("./Componantes/pages/Services"));
const Guide = lazy(() => import("./Componantes/pages/Guide"));
const Saloon = lazy(() => import("./Componantes/pages/Saloon"));

const MemoizedHome = React.memo(Home);
// const MemoizedHomeSoon = React.memo(HomeSoon);
const MemoizedContact = React.memo(Contact);
const MemoizedServices = React.memo(Services);
const MemoizedGuide = React.memo(Guide);
const MemoizedSaloon = React.memo(Saloon);

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <Suspense
      fallback={
        // <div className="flex items-center justify-center h-screen">
        //   <div className="flex flex-row gap-2">
        //     <div className="w-4 h-4 rounded-full bg-d8a7a7 animate-bounce duration-[380ms]"></div>
        //     <div className="w-4 h-4 rounded-full bg-d8a7a7 animate-bounce [animation-delay:-.3s]"></div>
        //     <div className="w-4 h-4 rounded-full bg-d8a7a7 animate-bounce [animation-delay:-.5s]"></div>
        //   </div>
        // </div>
        <div className="flex items-center justify-center h-screen">
          <div className="flex flex-row gap-2">
            <div className="w-4 h-4 rounded-full text-d8a7a7 animate-pulse text-lg duration-[700ms]">
              <FontAwesomeIcon icon={faHeart} />
            </div>
            <div className="w-4 h-4 rounded-full text-d8a7a7 animate-pulse text-lg  [animation-delay:-.3s]">
              <FontAwesomeIcon icon={faHeart} />
            </div>
            <div className="w-4 h-4 rounded-full text-d8a7a7 animate-pulse text-lg [animation-delay:-.5s]">
              <FontAwesomeIcon icon={faHeart} />
            </div>
            <div className="w-4 h-4 rounded-full text-d8a7a7 animate-pulse text-lg [animation-delay:-.6s]">
              <FontAwesomeIcon icon={faHeart} />
            </div>
            <div className="w-4 h-4 rounded-full text-d8a7a7 animate-pulse text-lg [animation-delay:-.7s]">
              <FontAwesomeIcon icon={faHeart} />
            </div>
            <div className="w-4 h-4 rounded-full text-d8a7a7 animate-pulse text-lg [animation-delay:-.8s]">
              <FontAwesomeIcon icon={faHeart} />
            </div>
            <div className="w-4 h-4 rounded-full text-d8a7a7 animate-pulse text-lg [animation-delay:-.9s]">
              <FontAwesomeIcon icon={faHeart} />
            </div>
          </div>
        </div>
      }
    >
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<MemoizedHome />} />
        {/* <Route path="/Home" element={<MemoizedHome />} /> */}
        <Route path="/contact" element={<MemoizedContact />} />
        <Route path="/services" element={<MemoizedServices />} />
        <Route path="/guide" element={<MemoizedGuide />} />
        <Route path="/saloon" element={<MemoizedSaloon />} >
          <Route path=":id" element={<MemoizedSaloon />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

// function delayForDemo(promise) {
//   return new Promise((resolve) => {
//     setTimeout(resolve, 730);
//   }).then(() => promise);
// }

export default AnimatedRoutes;
