import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import logo from "../logo/LOGO noBack.svg";
import "./Navbar.css";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
// import Search from "./Search";
import AnimationHead from "./AnimationHead";

const variants = {
  open: { duration: 1, delay: -10 },
  closed: { duration: 1, delay: -10 },
};
function CollapsibleExample() {
  const [scrollingStarted, setScrollingStarted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const size1 = 5;
  const size2 = 11;

  const initialWidth = isOpen ? size1 : size2;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrollingStarted(true);
      } else {
        setScrollingStarted(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Navbar
      collapseOnSelect
      variant=""
      expand="lg"
      bg="dark"
      className={`bg-body-tertiary ${scrollingStarted ? "fixed-navbar" : ""}`}
    >
      <Container>
        {/* <Search /> */}
        <br></br>

        <Nav.Link as={Link} to="/">
          <AnimationHead />
        </Nav.Link>
        {/* <Navbar.Brand as={Link} to="/">
          <motion.img
            className="imf-fluid"
            initial={{ opacity: 0, y: -90, scale: 2 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            transition={{
              duration: 1,
              delay: 0,
              type: "spring",
              stiffness: 50,
            }}
            whileTap={{ scale: 0.9 }}
            src={logo}
            alt="Brand Logo"
            width="75%"
          />
        </Navbar.Brand> */}
        <motion.nav animate={isOpen ? "open" : "closed"} variants={variants}>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="toggle"
            onClick={() => setIsOpen((prevState) => !prevState)}
          >
            <motion.div
              whileTap={{ scale: 0.8, originX: 0, originY: 0 }}
              transition={{ duration: 0.38 }}
            >
              <motion.span
                initial={{ opacity: 0, y: -150, scale: 2, width: initialWidth }}
                animate={{
                  opacity: 1,
                  y: 1,
                  scale: 1,
                  rotate: 360,
                  width: isOpen ? size1 : size2,
                  height: 1,
                }}
                transition={{
                  duration: 1,
                  delay: 1,
                  type: "spring",
                  stiffness: 50,
                }}
                className="custom-toggle-icon wide1"
              ></motion.span>
              <motion.span
                initial={{
                  opacity: 0,
                  y: -150,
                  scale: 2,
                  height: 1,
                  width: isOpen ? size1 : size2,
                }}
                animate={{
                  opacity: 1,
                  y: 0,
                  scale: 1,
                  rotate: 360,
                  height: 1,
                  width: isOpen ? size1 : size2,
                }}
                transition={{
                  duration: 1,
                  delay: 0.8,
                  type: "spring",
                  stiffness: 50,
                }}
                className="custom-toggle-icon wide2"
              ></motion.span>
              <motion.span
                initial={{
                  opacity: 0,
                  y: -150,
                  scale: 2,
                  height: 1,
                  width: isOpen ? size1 : size2,
                }}
                animate={{
                  opacity: 1,
                  y: 0,
                  scale: 1,
                  rotate: 360,
                  height: 1,
                  width: isOpen ? size1 : size2,
                }}
                transition={{
                  duration: 1,
                  delay: 0.6,
                  type: "spring",
                  stiffness: 50,
                }}
                className="custom-toggle-icon wide3"
              ></motion.span>
              <motion.span
                initial={{
                  opacity: 0,
                  y: -150,
                  scale: 2,
                  height: 1,
                  width: isOpen ? size1 : size2,
                }}
                animate={{
                  opacity: 1,
                  y: 0,
                  scale: 1,
                  rotate: 360,
                  height: 1,
                  width: isOpen ? size1 : size2,
                }}
                transition={{
                  duration: 1,
                  delay: 0.4,
                  type: "spring",
                  stiffness: 50,
                }}
                className="custom-toggle-icon wide3"
              ></motion.span>
            </motion.div>
          </Navbar.Toggle>
        </motion.nav>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto">
            {/* <motion.div
              initial={{ opacity: 0, y: -90, scale: 2, originX: 0, originY: 0 }}
              animate={{ opacity: 1, y: 0, scale: 1, originX: 0, originY: 0 }}
              transition={{
                duration: 1,
                delay: 0.4,
                type: "spring",
                stiffness: 75,
              }}
              whileTap={{ scale: 0.9, origin: 0 }}
              whileHover={{ scale: 1.1, originX: 0, originY: 0 }}
              className="wide-area-to-tap"
            > */}
            <Nav.Link as={Link} to="/">
              <span className="uppercase text-white text-lg font-bold text-right float-right">
                الرئيسية
              </span>
            </Nav.Link>
            {/* </motion.div> */}
            {/* <motion.div
              initial={{ opacity: 0, y: -90, scale: 2, originX: 0, originY: 0 }}
              animate={{ opacity: 1, y: 0, scale: 1, originX: 0, originY: 0 }}
              transition={{
                duration: 1,
                delay: 0.6,
                type: "spring",
                stiffness: 75,
              }}
              whileTap={{ scale: 0.9 }}
              whileHover={{ scale: 1.1, originX: 0, originY: 0 }}
              className="wide-area-to-tap"
            >
              <Nav.Link as={Link} to="/Services">
                <span className="uppercase text-white text-lg font-bold">
                  Services
                </span>
              </Nav.Link>
            </motion.div> */}
            {/* <motion.div
              initial={{ opacity: 0, y: -90, scale: 2, originX: 0, originY: 0 }}
              animate={{ opacity: 1, y: 0, scale: 1, originX: 0, originY: 0 }}
              transition={{
                duration: 1,
                delay: 0.8,
                type: "spring",
                stiffness: 75,
              }}
              whileTap={{ scale: 0.9 }}
              whileHover={{ scale: 1.1, originX: 0, originY: 0 }}
              className="wide-area-to-tap"
            >
              <Nav.Link as={Link} to="/Guide">
                <span className="uppercase text-white text-lg font-bold">
                  courses
                </span>
              </Nav.Link>
            </motion.div> */}
            {/* <motion.div
              initial={{ opacity: 0, y: -90, scale: 2, originX: 0, originY: 0 }}
              animate={{ opacity: 1, y: 0, scale: 1, originX: 0, originY: 0 }}
              transition={{
                duration: 1,
                delay: 0.8,
                type: "spring",
                stiffness: 75,
              }}
              whileTap={{ scale: 0.9 }}
              whileHover={{ scale: 1.1, originX: 0, originY: 0 }}
              className="wide-area-to-tap"
            >
              <Nav.Link as={Link} to="/Saloon">
                Saloon
              </Nav.Link>
            </motion.div> */}
            {/* <motion.div
              initial={{ opacity: 0, y: -90, scale: 2, originX: 0, originY: 0 }}
              animate={{ opacity: 1, y: 0, scale: 1, originX: 0, originY: 0 }}
              transition={{
                duration: 1,
                delay: 1,
                type: "spring",
                stiffness: 75,
              }}
              whileTap={{ scale: 0.9 }}
              whileHover={{ scale: 1.1, originX: 0, originY: 0 }}
              className="wide-area-to-tap"
            >
              <Nav.Link as={Link} to="/Contact">
                <div className="grid gap-2">
                  <span className="uppercase text-white text-xs">
                    {" "}
                    <span className="text-white uppercase border-1 p-1 border-solid border-white rounded-lg text-xs font-bold">
                      book appointment
                    </span>
                  </span>
                </div>
              </Nav.Link>
            </motion.div> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;
