// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer.css */
.footer {
  margin-top: auto;
}
@media (max-width: 767px) {
  .footer {
    /* margin: auto; */
  }
}
`, "",{"version":3,"sources":["webpack://./src/Componantes/Footer.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,gBAAgB;AAClB;AACA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":["/* Footer.css */\n.footer {\n  margin-top: auto;\n}\n@media (max-width: 767px) {\n  .footer {\n    /* margin: auto; */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
