import React from "react";
import "./Footer.css";
import logo from "../Sponsers/Powered By saja.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <Container fluid className="footer bg-a38f85">
      <Row className="justify-content-center align-items-center">
        <Col className="d-flex justify-content-center align-items-center">
          <Link to="/Contact" target="_blank" rel="noreferrer">
            <img
              src={logo}
              alt=""
              className=" img-fluid"
              style={{ height: 40, width: 300 }}
            />
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
