import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./App.css";
// import Navbar from './Componantes/Navbar';
import Navbar from "./Componantes/Nave2";
import Footer from "./Componantes/Footer";
import { BrowserRouter as Router } from "react-router-dom";
import AnimatedRoutes from "./AnimatedRoutes";
import { Container } from "react-bootstrap";
// import Sponser from "./Componantes/Sponser";
// import Home from "./Componantes/Home";

function App() {
  console.log("test");
  return (
    <>
      <div className="App">
        <Router>
          <Navbar />
          <Container>
            {/* <Home /> */}
            {/* <Sponser /> */}
            <AnimatedRoutes />
          </Container>
          <Footer />
        </Router>
      </div>
    </>
  );
}

export default App;
